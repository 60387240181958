/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//// Datos de CARDON PROD //////////////

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:6a40d056-837b-4e46-954c-a6bd0145144d",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_6QVdy4cQG",
  "aws_user_pools_web_client_id": "78v01v6k324okocbkl8ebo4tsa",
  "oauth": {},
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": []

};

export default awsmobile;

