import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { Observable } from "rxjs";

import { IconItem } from "../../core/models/icono-service.models";

@Injectable({
  providedIn: 'root'
})

export class IconoService {
    private Icontem: IconItem;

    constructor() { }

    private Icontem$: Subject<IconItem> = new Subject<IconItem>();
    public customIcon = this.Icontem$.asObservable();

    changeIcon$(icon: IconItem): void {
        this.Icontem$.next(icon);
    }
}
