<header id="page-topbar">
  <div class="navbar-header">
      <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
              <a href="/" class="logo logo-dark">
                  <span class="logo-sm">
             <img src="assets/images/Reliabytics/logo-reliabytics-26.png" alt="" height="22">
           </span>
                  <span class="logo-lg">
             <img src="assets/images/Reliabytics/reliabytics-20.png" alt="" height="17">
           </span>
              </a>

              <a href="/" class="logo logo-light">
                  <span class="logo-sm">
             <img src="assets/images/Reliabytics/logo-reliabytics-26.png" alt="" height="48" style="margin-left: -2px;">
           </span>
                  <span class="logo-lg">
             <img src="assets/images/Reliabytics/reliabytics-20.png" alt="" height="68" style="margin-left: -12px;">
           </span>
              </a>
          </div>

          <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
            <i class="fa fa-fw fa-bars"></i>
          </button>
      </div>

      <!-- ICONO DEL MODULO -->
      <div class="text-center">
          <div *ngIf="iconItem.isHome==false" class="text-center">
              <div *ngIf="iconItem.tipoIcom=='mdi'" style="height: 45px;">
                  <i class="mdi {{iconItem.icon}}" style="font-size: 36px; color:#154f87;" *ngIf="iconItem.tipoIcom=='mdi'"></i>
              </div>
              <div *ngIf=" iconItem.tipoIcom=='bx'">
                  <i class="bx {{iconItem.icon}}" style=" font-size: 36px; color:#154f87;"></i>
              </div>
              <div>
                  <span class="text-center" style="color:#154f87;">{{iconItem.label}}</span>
              </div>
          </div>
      </div>

      <div class="d-flex">
          <!--Idioma-->
          <div class="dropdown d-inline-block" ngbDropdown>
              <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                  <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
                      class="ms-1">{{countryName}}</span>
                  <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
                  <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
              </button>
              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
                      <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{item.text}}</span>
                  </a>
                  <!-- item-->
              </div>
          </div>

          <!-- Full Screen -->
          <div class="dropdown d-none d-lg-inline-block ms-1">
              <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
                  <i class="bx bx-fullscreen"></i>
              </button>
          </div>

          <!-- notificaciones -->
          <div *ngIf="permisoNotif==true" class="dropdown d-inline-block" ngbDropdown #myDrop="ngbDropdown">
            <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"  [attr.disabled]="disabNoti ? true : null"   ngbDropdownToggle>
                <i class="bx bx-bell bx-tada"></i>
                <span class="badge bg-danger rounded-pill">{{alarmaslegth}}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                <div class="p-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="m-0">{{'TOOBAR.TITULO_NOTIF_1'| translate}}</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript: void(0);" >
                              <p class="mb-0 font-size-12 text-center" (click)="enviarAlertaNotif(); myDrop.close();">{{'TOOBAR.TITULO_NOTIF_5'| translate}}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <ngx-simplebar style="position: relative; height: 230px;">
                    <div *ngFor="let t of alarmas">
                        <a href="javascript: void(0);" (click)="enviarAlertaNotif(t); myDrop.close();" class="text-reset notification-item">
                            <div class="d-flex">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-danger rounded-circle font-size-16">
                                        <i class=" mdi mdi-alarm-light"></i>
                                    </span>
                                </div>
                                <div class="flex-grow-1">
                                    <h6 class="mt-0 mb-1">{{t.condicion}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{t.senal}}</p>
                                        <p class="mb-1">{{t.fecha}}</p>
                                        <p class="mb-1">{{t.origen}}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" (click)="setAlertasLeida(t.id)">
                            <p class="mb-0"  style="margin-left: 76%;"><i class="mdi mdi-bell-check" style="margin-right: 3px;"></i>{{'TOOBAR.TITULO_NOTIF_6'| translate}}</p>
                        </a>
                    </div>
                </ngx-simplebar>
                <div class="p-2 border-top d-grid">
                    <!-- <a class="btn btn-sm btn-link font-size-13 text-center" href="javascript:void(0)" (click)="getDatosAlarmas()">
                        <i class="mdi mdi-arrow-down-circle" style="margin-right: 3px;"></i>{{'TOOBAR.TITULO_NOTIF_3'| translate}}</a> -->
                </div>
            </div>
          </div>

          <!-- Pefil  -->
          <div class="dropdown d-inline-block" ngbDropdown>
              <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
                <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
                <span class="d-none d-xl-inline-block ms-1">{{nombre}}</span>
                <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>

              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0);" (click)="alertSinPrivilegio(tituloInfo3)"><i class="bx bx-user font-size-16 align-middle mr-1"></i>{{'TOOBAR.TITULO_OPCION_1'| translate}}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item " href="javascript: void(0);" (click)="irNotificaciones()">
                  <i class="bx bx-check-circle font-size-16 align-middle me-1"></i>{{'TOOBAR.TITULO_OPCION_6'| translate}}
                </a>
                <div class="dropdown-divider"></div>
                  <a class="dropdown-item " href="javascript: void(0);" (click)="downLoadManual()">
                      <i class="bx bx-help-circle font-size-16 align-middle me-1"></i>{{'TOOBAR.TITULO_OPCION_3'| translate}}
                  </a>
                  <a class="dropdown-item " href="javascript: void(0);" (click)="alertSoprteReliabytics(tituloInfo4,tituloInfo5)">
                      <i class="bx bx bx-support font-size-16 align-middle me-1"></i>{{'TOOBAR.TITULO_OPCION_5'| translate}}
                  </a>
                  <a class="dropdown-item " href="javascript: void(0);" (click)="alertInfoReliabytics(tituloInfo2)">
                      <i class="bx bx-info-circle font-size-16 align-middle me-1"></i>{{'TOOBAR.TITULO_OPCION_4'| translate}}
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
                      <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{{'TOOBAR.TITULO_OPCION_2'| translate}}
                  </a>
              </div>
          </div>

      </div>
  </div>
</header>
