<div class="account-pages">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="text-center mb-2 mt-2">
                  <!-- <h1 class="display-2 fw-medium">5<i class="bx bx-buoy bx-spin text-primary display-3"></i>0</h1> -->
                  <h1 class="text-uppercase">{{'PAGEFAULT.TEXTO_1'| translate}}</h1>
                  <div class="mt-3 text-center">
                      <h5>{{'PAGEFAULT.TEXTO_2'| translate}}</h5>
                      <h6>Status: {{errStatus}}</h6>
                      <h6>Error: {{errHttp}}</h6>
                      <h6>Message: {{errMessage}}</h6>
                  </div>
                  <div class="mt-5 text-center">
                      <a class="btn btn-primary" routerLink="/">{{'PAGEFAULT.TEXTO_3'| translate}}</a>
                  </div>
              </div>
          </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-md-8 col-xl-6">
              <div>
                  <img src="assets/images/error-img.png" alt="" class="img-fluid">
              </div>
          </div>
      </div>
  </div>
</div>
